import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAt } from "@fortawesome/free-solid-svg-icons"

const Page = () => (
  <Layout container={false}>


    <SEO title="Studientisches Praktikum Website-Design & Film in Dresden"
         description={"Als Agentur aus ehemaligen Studierenden bieten wir anderen Studierenden die Möglichkeit, in einem Praktikum Einblicke in die tägliche Arbeit einer jungen Werbeagentur zu erhalten und eigene Ideen einzubringen"} />

    <div className={"container"} style={{
      maxWidth: 1000,
      margin: "auto",
    }}>

      <div style={{ height: 120 }}></div>
      <h1>Praktikum Website-Design & Film in Dresden</h1>
      <img src={require("../images/praktikum/praktikum.jpg")} className={"hero"}
           style={{ width: "100%", borderRadius: "1rem" }}
           alt="Praktikum Website-Design & Film in Dresden"
      />

      <article className="big-headings block__prose max- px-8 md:px-8 xl:px-0">
        <main className="job-posting" style={{
          background: "#eee",
          borderRadius: "1rem",
          padding: "1.5rem",
          border: "1px solid #ddd",
          marginTop: "1rem",
        }}>
          <div style={{ display: "flex", justifyContent: "start", gap: 20, alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
              <path fill="orange"
                    d="m7.625 6.4l2.8-3.625q.3-.4.713-.587T12 2q.45 0 .863.188t.712.587l2.8 3.625l4.25 1.425q.65.2 1.025.738t.375 1.187q0 .3-.088.6t-.287.575l-2.75 3.9l.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25q-.125.05-.275.063T6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125l-2.725-3.875q-.2-.275-.288-.575T2 9.75q0-.625.363-1.162t1.012-.763z" />
            </svg>
            <h1 style={{ margin: 0 }}>Studentisches Praktikum</h1>
          </div>

          <h2 style={{ color: "#000", marginBottom: 20 }}>Als Agentur aus ehemaligen Studierenden bieten wir anderen
            Studierenden die Möglichkeit, in einem
            Praktikum
            Einblicke in die tägliche Arbeit einer jungen Werbeagentur zu erhalten und eigene Ideen einzubringen.</h2>

          <div className="place" style={{ marginBottom: 10, display: "flex", gap: 10, alignItems: "center" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
              <path
                d="M12 12q.825 0 1.413-.587T14 10q0-.825-.587-1.412T12 8q-.825 0-1.412.588T10 10q0 .825.588 1.413T12 12m0 10q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2q3.175 0 5.588 2.225T20 10.2q0 2.5-1.987 5.438T12 22" />
            </svg>
            Nürnberger Ei (ca. 1km zur Technischen Universität Dresden)
          </div>

          <div className="place" style={{ marginBottom: 0, display: "flex", gap: 10, alignItems: "center" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
              <path
                d="M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v4.675q0 .425-.288.713t-.712.287q-.425 0-.712-.288T19 10.676V10H5v10h5.8q.425 0 .713.288T11.8 21q0 .425-.288.713T10.8 22zm13 1q-2.075 0-3.537-1.463T13 18q0-2.075 1.463-3.537T18 13q2.075 0 3.538 1.463T23 18q0 2.075-1.463 3.538T18 23m1.675-2.625l.7-.7L18.5 17.8V15h-1v3.2z" />
            </svg>
            <span>Frühester Beginn <u>in 14 Tagen</u></span>
          </div>


        </main>

        <h1>Wir</h1>
        <p>Du magst Kameras, Computer oder Webbrowser und
          hast schonmal überlegt, Dich
          von
          einem dieser Sachen im Berufsleben begleiten zu lassen? Vielleicht hast Du ja sogar schon die ein oder
          andere
          Erfahrung mit diesen Themen.</p>


        <p className="has-medium-font-size">Wir sind eine junge Werbeagentur aus Dresden und Stuttgart und beschäftigen
          uns
          täglich mit genau diesen Dingen. Vor einigen Jahren waren wir selbst an diesem Punkt, durften schon viel von
          anderen lernen und wollen genau das weitergeben. Und wer weiß, welche neuen Ideen Du ins Unternehmen bringst?
          Wir sind
          überzeugt:
          Alle Beteiligten profitieren von einem Praktikum!</p>


        <h1>Übersicht</h1>
        <ul className="has-medium-font-size">
          <li>Du wirst von einem Mentor unterstützt</li>

          <li>Du kannst relativ flexibel arbeiten</li>

          <li>Du kannst sehr gerne eigene Ideen und Vorschläge einbringen, wir sind offen für Neues</li>

          <li>Du bekommst Einblick in Arbeitsabläufe und Tipps und Tricks</li>

          <li>Du erstellst Deine eigene Webseite mit unserer Unterstützung</li>

          <li>Du kannst unsere Studio Technik vor Ort auch für eigene Projekte nutzen</li>

          <li>Du kannst an laufenden Projekten mitdenken und mitarbeiten</li>

          <li>Wir ham nen Sportplatz vor der Tür</li>

        </ul>

        <h1>Aufgaben</h1>

        <ul className="has-medium-font-size">
          <li>Einarbeitung in Grundlagen des Webdesign sowie der Bild- und Videobearbeitung.</li>


          <li>Unterstützung in alltäglichen Aufgaben, u.a. Inhaltspflege, Recherche für Design Entwürfe.</li>


          <li>Du kannst an aktuellen, vergangenen und fiktiven Projekten eigene Ideen ausprobieren.</li>


          <li>Wir sprechen regelmäßig über:
            <ul>
              <li>Was habe ich Neues gelernt?</li>
              <li>Was steht mir aktuell im Weg, was kann ich verbessern?</li>
              <li>Was erreiche ich bis nächste Woche?</li>
              <li>Wo brauche ich Hilfe?</li>
            </ul>
          </li>
        </ul>


        <h1>Profil</h1>

        <ul className="has-medium-font-size">
          <li>Aufmerksames Auge und Sinn für Ästhetik, da wir viel mit Bildern arbeiten</li>

          <li>Kreatives Denken und die Fähigkeit, Ideen in irgendeiner Art zu visualisieren und zu kommunizieren</li>

          <li>Eigeninitiative und die Fähigkeit, sich selbst zu organisieren</li>
        </ul>


      </article>
    </div>
    <div className={"container-fluid bg-green"}>
      <div
        style={{
          maxWidth: 1000,
          margin: "auto",
          marginBottom: 30,
          padding: "1.5rem",
        }}>
        <h1>Bewerbung</h1>
        <ul className="checkbox">
          <li>2-3 Projekte/Ideen, die Du umgesetzt hast oder umsetzen möchtest</li>
          <li>Nenne uns Deinen Wunschzeitraum für das Praktikum</li>
          <li>(optional) Du kannst schon überlegen, was Dein Ziel für das Praktikum ist</li>
          <li>(optional) Liste mit relevanter Software, die Du bereits kennst und benutzt</li>
        </ul>
        <p>Bitte per E-Mail an <a className="font-bold underline"
                                  style={{ textDecoration: "underline" }}
                                  href="mailto:info@ziegenhagel.com">info@ziegenhagel.com</a>.
        </p>
        <p>
          Wenn Du eine ganz andere Idee zur Bewerbung hast, dann mach das auch gern :)</p>
      </div>
    </div>
  </Layout>
)

export default Page
